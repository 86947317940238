body {
    padding: 0px;
    font-family: sans-serif;
}

.reveal.small {
    border-radius: 6px;
    background-color: #efefef;
}

.switch-paddle,
.switch-paddle:after {
    -webkit-border-radius: 3px !important;
    -moz-border-radius: 3px !important;
    border-radius: 3px !important;
}

button.radius {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

button.rounded {
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
    border: 1px solid #999;
}

.verticalCenter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.verticalTop {
    position: relative;
    top: 60px;
    margin: 0 auto;
}

.react-target {
    width: 102%;
    height: 150vh;
    margin: 0 !important;
}

.dialogContainer {
    border: 1px #999 solid;
    padding: 12px;
    background-color: rgba(200, 200, 200, .7);
    /* opacity: .5; */
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 0 auto;
}

div.stick-to-top {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
}

div.excercise {
    width: 90%;
    margin: auto 12px auto 12px;
    border-bottom: 1px solid #aaa;
}

.nav-modal {
    // max-width: 90% !important;
    margin: 0 auto !important;
    background-color: rgba(200, 200, 200, .95) !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
    border: 1px #999 solid !important;
}

input,
select {
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
}

.navigation-bar {
    margin: 0;
    background-color: rgba(255, 255, 255, .5);
}

.button .spinning {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

.preferences-title {
    float: left;
    position: relative;
    top: -30px;
    font-weight: bold;
}

.menu-container {
    position: relative;
    top: 20px;
    // left: 40px;
    float: right;
    margin-right: 20px;
}

.menu {
    background: transparent;
    border-radius: 8px;
    position: absolute;
    top: 40px;
    right: 15px;
    max-width: 300px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 8px;
}

.menu li {
    opacity: .7;
    white-space: nowrap;
}

.menu li:hover {
    background-color: #ececec;
}

.li-border {
    border-bottom: 1px solid #dddddd;
}

.li-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.li-bottom {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.menu li a {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
}

.menu-trigger {
    background: transparent;
    border-radius: 90px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
}

.menu-trigger:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;
}

.menu-trigger img {
    border-radius: 90px;
}

.undernav {
    position: relative;
    top: 75px;
    min-width: 350px;
}

.pageDisbled {
    color: #dedede;
    position: relative;
    display: inline;
    top: -27px;
}

.pageActive {
    color: #990000;
    position: relative;
    display: inline;
    top: -27px;
}


table {
    border-collapse: separate;
    border-spacing: 0;
    min-width: 320px;
}

table tr th {
    border-bottom: 1px solid #cfcfcf;
}

table tr th,
table tr td {
    // border-right: 1px solid #bbb;
    // border-bottom: 1px solid #bbb;
    padding: 7px;
}

table tr th:last-child,
table tr td:last-child {
    border-right: 1px solid #bbb;
}

table tr th:first-child,
table tr td:first-child {
    border-left: 1px solid #bbb;
}

table tr th {
    background: #eee;
    border-top: 1px solid #bbb;
    text-align: left;
}

/* top-left border-radius */
table tr:first-child th:first-child {
    border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
    border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}